import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import TextArea from "../components/TextArea"
import Button from "../components/Button"
import { FiArrowRight } from "react-icons/fi"
import styled from "styled-components"
import {
  compose,
  color,
  space,
  typography,
  layout,
  textAlign,
  fontSize,
  flexbox,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
} from "styled-system"

const Box = styled("div")(
  {
    boxSizing: "border-box",
  },
  space,
  layout,
  flexbox
)

const Flex = styled("div")(
  {
    display: "flex",
  },
  space,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
)

const Text = styled.span`
  ${textAlign}
  ${fontSize}
  ${space}
  ${color}
`

const Row = styled.div`
  ${space}
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
`
const StyledBox = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
`

const PaperBox = styled(Box)`
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

const StyledLink = styled.a`
  color: #495057;
  text-decoration: none;
`

function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const IndexPage = props => {
  return (
    <Layout>
      <SEO title="Online JSON storage" />

      <div style={{ marginBottom: `1.45rem` }}>
        <h1 style={{ fontSize: "1.5rem", textAlign: "center" }}>
          JSON storage that helps developers
        </h1>
        <Row mb={4}>
          <StyledBox>
            <PaperBox p={4} mb={2}>
              <Flex flexDirection="column">
                <Box mb={3}>
                  JSONSERVE is a service that helps developers to store JSON
                  objects and use that JSON as a REST API in their app.
                </Box>

                <Box mb={3}>
                  As a front-end developer, I encountered many times next
                  difficulty:
                </Box>
                <Box mb={3}>
                  While building an app that communicates with remote service
                  via REST API, there is a constant need for that service
                  developer to make changes - as small updates or error fixes -
                  and deploy those changes.
                </Box>
                <Box mb={3}>This process taking precious time.</Box>
                <Box mb={3}>
                  JSONSERVE allows you to store your JSON online for free,
                  without registration. It's like hosting for JSON files. Just
                  paste a JSON object into the text area, save it, and use the
                  given URL as REST API in your app. Simple as that!
                </Box>
              </Flex>
            </PaperBox>
          </StyledBox>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage
